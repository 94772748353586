

/* container for disabling scrollbar  */
.dragAndDropBox {
    
    min-height: 5rem;
    width: 100%;
    margin: 0;
    border: 2px solid black;
    padding: 5px;
}
